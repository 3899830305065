import {Injectable, OnDestroy} from '@angular/core';
import {AppConfig} from '../app.config';
import { Observable, firstValueFrom } from 'rxjs';
import {CurrentUserService} from './currentuser.service';
import {AuthenticationService} from './authentication.service';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root',
})
export class ConsentService implements OnDestroy {

  public static ENDPOINT = AppConfig.UAA_URL + '/api/consent';

  private consentSubscription;

  private userIdSubscription;

  constructor(
    private currentUserService: CurrentUserService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  public checkConsent(login: string) {

    this.authenticationService.findUserIdFromEmail(login).then(
      (userId) => {
        this.currentUserService.setUserId( userId );
        this.isConsentGiven().then(
          (consentValue) => {
            this.routeBasedOnConsentValue( consentValue );
          }          
        ).catch(
        (error) => {
          this.routeBasedOnConsentValue( false );
        });
      });

  }


  private routeBasedOnConsentValue(consentValue) {
    if (!Boolean(consentValue)) {
      this.router.navigate(['consent']);
    }
    return;
  }

  async isConsentGiven(): Promise<string> {
    const userId = this.currentUserService.getUserId();
    const url = ConsentService.ENDPOINT + '/' + userId;
    return await firstValueFrom( this.authenticationService.get(url) );
  }

  giveConsent(): Observable<any> {
    const userId = this.currentUserService.getUserId();
    const request = this.authenticationService.serializeObj({userId: userId});
    const observable = this.authenticationService.post(ConsentService.ENDPOINT, request);
    return observable;
  }

  ngOnDestroy(): void {
    if (this.consentSubscription != null) {
      this.consentSubscription.unsubscribe();
    }
    if (this.userIdSubscription != null) {
      this.userIdSubscription.unsubscribe();
    }
  }

}
