  
  <!--
  <div class="datepickerelement" name="mydivdate" [(ngModel)]="model" [options]="myDatePickerOptions"
       angular-mydatepicker #dp="angular-mydatepicker" [ngClass]="{'placeholdercolor': !dp.isDateValid()}"
       (dateChanged)="onDateChanged($event)" (click)="dp.toggleCalendar()"
  >
    {{ model?.singleDate?.jsDate | date: 'yyyy-MM-dd'}} &nbsp; <i class="fa fa-calendar" aria-hidden="true"></i>
  </div>
  -->
  

  <mat-form-field appearance="outline" class="mat-calendar-body-cell-content mat-focus-indicator ">

    <input class="mat-calendar-body-selected mat-calendar-body-today"
      matInput 
      disabled
      [matDatepicker]="datePickerOne"
      (change)="onDateChanged($event.value)"/>
    <mat-datepicker-toggle matSuffix [for]="datePickerOne" [disabled]="false">
    </mat-datepicker-toggle>
    <mat-datepicker #datePickerOne [disabled]="false">
    </mat-datepicker>
  
  </mat-form-field>

  <!--
  <input matInput 
    [matDatepicker]="picker" 
    placeholder="Custom Icon Datepicker"
    (dateChange)="onDateChanged($event)"
    >
    <mat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon>settings</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  -->


