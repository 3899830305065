import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConsentService} from '../../services/consent.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html'
})
export class ConsentComponent implements OnInit, OnDestroy {

  private _checked = false;

  private giveConsentSubscription;

  constructor(
    private consentService: ConsentService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public giveConsent() {
    this.giveConsentSubscription =    this.consentService.giveConsent()
      .subscribe({
       next: (value) => {
        console.log('consent given: ' + value);
        this.router.navigate(['/visitors']);
       }, 
       error: (error) => {
        console.log('error: ' + error);
        this.router.navigate(['/consent']);
      }
    });
  }


  get checked() {
    return this._checked;
  }

  set checked(value) {
    console.log('consent given');
    this._checked = value;
  }

  ngOnDestroy(): void {
    if (this.giveConsentSubscription != null) {
      this.giveConsentSubscription.unsubscribe();
    }
  }
}
