import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {AppConfig} from '../../app.config';
import {Observable} from 'rxjs';

@Injectable()
export class AppGraphService {

  constructor(private authService: AuthenticationService) {

  }


  public getFromSocialMediaByDay(projectId: string, name: string): Observable<any> {
    const urlCounts = AppConfig.SOCIALS_ENDPOINT_PREFIX + name + '/' + projectId;
    return this.authService.get(urlCounts);
  }


}

