<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">


      <div class="col-md-8">

        <img src="/assets/img/logo.png"/>

        <div class="card-group mb-4">

          <div class="card p-4">
            <div class="card-body">
              <h1>Privacy policy &amp; Terms and conditions</h1>

              <div style="max-height: 500px; overflow-y:auto; margin: 20px;">
                Last updated March 29, 2020<br/>
              </div>


              <div style="max-height: 300px; overflow-y:auto; margin: 20px;">
                <br/>
                <h4>General Information</h4>
                ExtraWatch 4.0 is software as a service, it stores anonymized data into the cloud servers and NOT into your local database (just like Google Analytics, and other similar services).
                By purchasing the ExtraWatch PRO license, you purchase the right to use service for the limited time, not the source code or other artifacts of the service.
                We reserve a right to cancel the service according to the terms and conditions of use or for any other reason.
                Source codes and other artifacts which the ExtraWatch&trade; services consists of, are copyright of the CodeGravity s.r.o.
                Source codes of the Joomla / Wordpress extensions are distributed with the GNU General Public License v3 http://www.gnu.org/licenses/gpl-3.0.txt
                <br/>
                <br/>
                <h4>Disclaimer of Warranty.</h4>
                THERE IS NO WARRANTY FOR THE PROGRAM, TO THE EXTENT PERMITTED BY APPLICABLE LAW. EXCEPT WHEN OTHERWISE STATED IN WRITING THE COPYRIGHT HOLDERS AND/OR OTHER PARTIES PROVIDE THE PROGRAM “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE PROGRAM IS WITH YOU. SHOULD THE PROGRAM PROVE DEFECTIVE, YOU ASSUME THE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION.
                <br/>
                <br/>
                <h4>Limitation of Liability.</h4>
                IN NO EVENT UNLESS REQUIRED BY APPLICABLE LAW OR AGREED TO IN WRITING WILL ANY COPYRIGHT HOLDER, OR ANY OTHER PARTY WHO MODIFIES AND/OR CONVEYS THE PROGRAM AS PERMITTED ABOVE, BE LIABLE TO YOU FOR DAMAGES, INCLUDING ANY GENERAL, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE THE PROGRAM (INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR DATA BEING RENDERED INACCURATE OR LOSSES SUSTAINED BY YOU OR THIRD PARTIES OR A FAILURE OF THE PROGRAM TO OPERATE WITH ANY OTHER PROGRAMS), EVEN IF SUCH HOLDER OR OTHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                <br/>
                <br/>
                <h4>Interpretation of Above Sections</h4>
                If the disclaimer of warranty and limitation of liability provided above cannot be given local legal effect according to their terms, reviewing courts shall apply local law that most closely approximates an absolute waiver of all civil liability in connection with the Program, unless a warranty or assumption of liability accompanies a copy of the Program in return for a fee.
                <br/>
                <br/>
                <h4>Privacy policy &amp; Terms and conditions of use</h4>
                ExtraWatch.com owned by CodeGravity s.r.o. (“company”, “we”, “us”, or “our”) is committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at info&#64;extrawatch.com or live support chat at <a href="https://www.extrawatch.com" target="_blank">extrawatch.com</a>.<br/>
                <br/>
                When you visit our website <a href="https://app.extrawatch.com" target="_blank">https://app.extrawatch.com</a>, mobile application, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Sites or Apps and our services.<br/>
                <br/>
                This privacy policy applies to all information collected through our website (such as <a href="https://app.extrawatch.com" target="_blank">https://app.extrawatch.com</a>), mobile application, ("Apps"), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Sites").<br/>
                <br/>
                Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.<br/>
                <br/>
                <br/>
                TABLE OF CONTENTS<br/>
                <br/>
                1. CONTENT BOUNDARIES<br/>
                2. WHAT INFORMATION DO WE COLLECT?<br/>
                3. HOW DO WE USE YOUR INFORMATION?<br/>
                4. WILL YOUR INFORMATION BE SHARED WITH ANYONE?<br/>
                5. WHO WILL YOUR INFORMATION BE SHARED WITH?<br/>
                6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?<br/>
                7. DO WE USE GOOGLE MAPS?<br/>
                8. HOW LONG DO WE KEEP YOUR INFORMATION?<br/>
                9. HOW DO WE KEEP YOUR INFORMATION SAFE?<br/>
                10. DO WE COLLECT INFORMATION FROM MINORS?<br/>
                11. WHAT ARE YOUR PRIVACY RIGHTS?<br/>
                12. CONTROLS FOR DO-NOT-TRACK FEATURES<br/>
                13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?<br/>
                14. DO WE MAKE UPDATES TO THIS POLICY?<br/>
                15. HOW CAN YOU CONTACT US ABOUT THIS POLICY?<br/>
                <br/>

                <h4>1. CONTENT BOUNDARIES</h4>
                Our content policies play an important role in maintaining a positive experience for you, the users. Please respect these guidelines.
                <br/>
                <br/>
                Adult Content: We do NOT allow adult content to be monitored by ExtraWatch, including images or videos that contain nudity or sexual activity.
                <br/>
                <br/>
                Child safety: We have a zero-tolerance policy towards monitoring websites that exploits children. Some examples of this include:
                Child sexual abuse imagery: We will terminate the accounts of any user we find publishing or distributing child sexual abuse imagery. We will also report that user to the National Center for Missing and Exploited Children, as required by law.
                Pedophilia: We do not allow content that encourages or promotes sexual attraction towards children. For example, do not use ExtraWatch with galleries of images of children where the collection of images or text accompanying the images is sexually suggestive, and do not publish content of a sexual nature involving an underage participant.
                <br/>
                <br/>
                Hate Speech: Our products are platforms for free expression, but we don’t permit hate speech. Hate speech is content that promotes or condones violence against or has the primary purpose of inciting hatred against an individual or group on the basis of their race or ethnic origin, religion, disability, age, nationality, veteran status, sexual orientation, gender, gender identity, or any other characteristic that is associated with systemic discrimination or marginalization.
                <br/>
                <br/>
                Crude Content: We don't allow to use ExtraWatch on websites that post content just to be shocking or graphic. For example, collections of close-up images of gunshot wounds or accident scenes without additional context or commentary would violate this policy.
                <br/>
                <br/>
                Violence: We don't allow to use ExtraWatch on websites which post violent or gory content that's primarily intended to be shocking, sensational, or gratuitous. If posting graphic content in a news, documentary, scientific, or artistic context, please be mindful to provide enough information to help people understand what's going on. In some cases, content may be so violent or shocking that no amount of context will allow that content to remain on our platforms. Lastly, don't encourage others to commit specific acts of violence.
                <br/>
                <br/>
                Terrorist Content: We do not permit terrorist organizations to use ExtraWatch for any purpose, including recruitment. ExtraWatch also strictly prohibits content related to terrorism, such as content that promotes terrorist acts, incites violence, or celebrates terrorist attacks.
                <br/>
                <br/>
                Harassment and Threats: Do not harass or bully others. Anyone using ExtraWatch monitoring to harass or bully may have the offending content removed or be permanently banned from the site. Online harassment is also illegal in many places and can have serious offline consequences. Don't threaten other people. For example, don't post death threats against another person or group of people and don't post content encouraging your readers to take violent action against another person or group of people.
                <br/>
                <br/>
                Personal and confidential information: We do not allow to use ExtraWatch on websites which publish another person's personal and confidential information. For example, someone else's credit card numbers, Social Security numbers, unlisted phone numbers, and driver's license numbers. In addition, do not post or distribute images or videos of minors without the necessary consent from their legal representatives. If someone has posted an image or video of a minor without necessary consent, please report it to us here. Also, please keep in mind that in most cases, information that is already available elsewhere on the Internet or in public records is not considered to be private or confidential under our policies.
                <br/>
                <br/>
                Impersonation and misrepresentation: Do not use ExtraWatch with the intent to deceive, mislead or confuse users by impersonating any person or organization you do not represent, or by concealing your ownership or primary purpose. This includes directing content about politics, social issues, or matters of public concern to users in a country other than your own, if you misrepresent or intentionally conceal your country of origin or other material details about yourself. We're not saying you can't publish parody or satire, or use a pseudonym or pen name - just avoid content that is likely to mislead readers about your true identity.
                <br/>
                <br/>
                Illegal activities: Do not use ExtraWatch to engage in illegal activities or to promote dangerous and illegal activities. For example, encouraging people to drink and drive. Please also do not use ExtraWatch to sell or promote illegal drugs. Otherwise, we may delete your content. Also, in serious cases such as those involving the abuse of children, we may report you to the appropriate authorities.
                <br/>
                <br/>
                Regulated Goods and Services: Do not use ExtraWatch to sell or facilitate the sale of regulated goods and services, such as alcohol, gambling, pharmaceuticals and unapproved supplements, tobacco, fireworks, weapons, or health/medical devices.
                <br/>
                <br/>
                Spam: Spam takes several forms in ExtraWatch, all of which can result in deletion of your account. Some examples include cases to drive traffic to your site or to move it up in search listings, posting comments on other people's posts just to promote your site or product, and scraping existing content from other sources for the primary purpose of generating revenue or other personal gains.
                <br/>
                <br/>
                Malware and viruses: We do not allow to use ExtraWatch on websites, which transmit viruses, cause pop-ups, attempt to install software without the reader's consent, or otherwise impact readers with malicious code. This is strictly forbidden.
                <br/>
                <br/>
                <h4>2. WHAT INFORMATION DO WE COLLECT?</h4>
                Information automatically collected<br/>
                <br/>
                As mentioned above, ExtraWatch 4.0 is designed as Software as a Service ("SaaS") and data are stored on cloud servers and not on your own hosting server.
                <br/>
                Performance of your hosting server is therefore not affected in any way, you can access your data of multiple websites from one account.
                <br/>
                The application functionality is not affected by any 3rd party CMS plugins or specific configuration of your web hosting environment.
                <br/>
                In Short: Some information – such as IP address and/or browser and device characteristics – is collected automatically when you or user of your website or this application.<br/>
                <br/>
                We automatically collect certain information when you visit, use or navigate the Sites or Apps. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Sites or Apps and other technical information. This information is primarily needed to maintain the security and operation of our Sites or Apps, and for our internal analytics and reporting purposes.<br/>
                <br/>
                Every IP address we store on our servers we attempt to anonymize and replace last part of the IP address octet. Like many businesses, we also collect information through cookies and similar technologies.<br/>
                <br/>
                <br/>
                Information collected through our Apps<br/>
                <br/>
                In Short: We may collect information regarding your geo-location, when you use our apps.<br/>
                <br/>
                If you use our Apps, we may also collect the following information:<br/>
                <br/>
                Geo-Location Information. We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using our mobile application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings.<br/>
                <br/>
                <br/>
                Information collected from other sources<br/>
                <br/>
                In Short: We may collect limited data from public databases, marketing partners, and other outside sources.<br/>
                <br/>
                We may obtain information about you from other sources, such as public databases, joint marketing partners, as well as from other third parties. Examples of the information we receive from other sources include: social media profile information; marketing leads and search results and links, including paid listings (such as sponsored links).<br/>
                <br/>
                <br/>
                <h4>3. HOW DO WE USE YOUR INFORMATION?</h4>
                In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.<br/>
                <br/>
                We use personal information collected via our Sites or Apps for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests ("Business Purposes"), in order to enter into or perform a contract with you ("Contractual"), with your consent ("Consent"), and/or for compliance with our legal obligations ("Legal Reasons"). We indicate the specific processing grounds we rely on next to each purpose listed below.<br/>
                <br/>
                We use the information we collect or receive:<br/>
                <br/>
                To facilitate account creation and logon process.If you choose to link your account with us to a third party account *(such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process.<br/>
                <br/>
                Fulfill and manage your orders. We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Sites or Apps.<br/>
                <br/>
                To enforce our terms, conditions and policies.<br/>
                <br/>
                To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.<br/>
                <br/>
                For other Business Purposes. We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Sites or Apps, products, services, marketing and your experience.<br/>
                <br/>
                <br/>
                <h4>4. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h4>
                In Short: We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.<br/>
                <br/>
                <br/>
                We may process or share data based on the following legal basis:<br/>
                Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose.<br/>
                <br/>
                Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.<br/>
                <br/>
                Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.<br/>
                <br/>
                Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).<br/>
                <br/>
                Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.<br/>
                More specifically, we may need to process your data or share your personal information in the following situations:<br/>
                <br/>
                Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Sites or Apps, which will enable them to collect data about how you interact with the Sites or Apps over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.<br/>
                <br/>
                Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.<br/>
                <br/>
                <br/>
                <h4>5. WHO WILL YOUR INFORMATION BE SHARED WITH?</h4>
                In Short:  We only share information with the following third parties.<br/>
                <br/>
                We only share and disclose your information with the following third parties. We have categorized each party so that you may be easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us.<br/>
                Communicate and Chat with Users:<br/>
                <li>LimeTalk.com</li>
                Invoice and Billing:<br/>
                <li>Paddle.com</li>
                Web and Mobile Analytics:<br/>
                <li>Google Analytics and StatCounter</li>
                Hosting:<br/>
                <li>contabo.com</li>
                <br/>
                <br/>
                <h4>6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
                In Short: We may use cookies and other tracking technologies to collect and store your information.<br/>
                <br/>
                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.<br/>
                <br/>
                <br/>
                <h4>7. DO WE USE GOOGLE MAPS?</h4>
                In Short: Yes, we use Google Maps for the purpose of providing better service.<br/>
                <br/>
                This website, mobile application, or Facebook application uses Google Maps APIs. You may find the Google Maps APIs Terms of Service here. To better understand Google’s Privacy Policy, please refer to this link.<br/>
                <br/>
                By using our Maps API Implementation, you agree to be bound by Google’s Terms of Service. By using our implementation of the Google Maps APIs, you agree to allow us to gain access to information about you including personally identifiable information (such as usernames) and non-personally identifiable information (such as location). We will be collecting the following information:<br/>
                <br/>
                ■  Anonymized IP addresses<br/>
                <br/>
                For a full list of what we use information for, please see the previous section titled "HOW DO WE USE YOUR INFORMATION?" and “WILL YOUR INFORMATION BE SHARED WITH ANYONE?” You agree to allow us to obtain or cache your location. You may revoke your consent at anytime. We use information about location in conjunction with data from other data providers.<br/>
                <br/>
                The Maps APIs that we use store and access cookies and other information on your devices. If you are a user currently in the European Union, please take a look at our EU User Consent Policy.<br/>
                <br/>
                <br/>
                <h4>8. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
                In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.<br/>
                <br/>
                We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than the period of time in which users have an account with us.<br/>
                <br/>
                When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.<br/>
                <br/>
                <br/>
                <h4>9. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
                In Short: We aim to protect your personal information through a system of organisational and technical security measures.<br/>
                <br/>
                We have implemented appropriate technical and organisational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our Sites or Apps is at your own risk. You should only access the services within a secure environment.
                By using our services, you accept this risk and will not held us responsible in any way in case of any security breach or leak of data.<br/>
                <br/>
                <br/>
                <h4>10. DO WE COLLECT INFORMATION FROM MINORS?</h4>
                In Short: We do not knowingly collect data from or market to children under 13 years of age.<br/>
                <br/>
                We do not knowingly solicit data from or market to children under 13 years of age. By using the Sites or Apps, you represent that you are at least 13 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Sites or Apps. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 13, please contact us at info&#64;extrawatch.com.<br/>
                <br/>
                <br/>
                <h4>11. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
                In Short: In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.<br/>
                <br/>
                In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.<br/>
                <br/>
                If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.<br/>
                <br/>
                If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm<br/>
                <br/>
                <br/>
                Account Information<br/>
                <br/>
                If you would at any time like to review or change the information in your account or terminate your account, you can:<br/>
                <br/>
                ■  Log into your account settings and update your user account.<br/>
                <br/>
                Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.<br/>
                <br/>
                Cookies and similar technologies:  Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Sites or Apps. <br/>
                <br/>
                Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we will still need to send you service-related emails that are necessary for the administration and use of your account. To otherwise opt-out, you may:<br/>
                <br/>
                ■  Access your account settings and update preferences.<br/>
                <br/>
                ■  Contact us using the contact information provided.<br/>
                <br/>
                ■  Note your preferences when you register an account with the site.<br/>
                <br/>
                <br/>
                <h4>12. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.<br/>
                <br/>
                <br/>
                <h4>13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>
                In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.<br/>
                <br/>
                California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.<br/>
                <br/>
                If you are under 18 years of age, reside in California, and have a registered account with the Sites or Apps, you have the right to request removal of unwanted data that you publicly post on the Sites or Apps. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Sites or Apps, but please be aware that the data may not be completely or comprehensively removed from our systems.<br/>
                <br/>
                <br/>
                <h4>14. DO WE MAKE UPDATES TO THIS POLICY?</h4>
                In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.<br/>
                <br/>
                We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.<br/>
                <br/>
                <br/>
                <h4>15. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h4>
                If you have questions or comments about this policy, you may email us at info&#64;extrawatch.com or by post to:<br/>
                <br/>
                CodeGravity s.r.o., Cordakova 13, 04023 Kosice, Slovakia, European Union<br/>
                <br/>
                <br/>
              </div>

              <div class="row">
                <div class="col-10">
                  <label>
                    <input type="checkbox" (checked)="[(checked)]"  (change)="checked = !checked" /> I read and agree with the Privacy Policy &amp; Terms and conditions of use
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <br/>
                  <button type="button" [disabled]="(!checked)" class="btn btn-primary px-4" (click)="giveConsent()">Continue</button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>

