import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {CurrentUserService} from './currentuser.service';
import {Observable} from 'rxjs/internal/Observable';
import {AppConfig} from '../app.config';


@Injectable()
export class SettingsService {

  readonly GET_REMOTE_IP_ADDRESS = '/api/getRemoteIpAddress';

  readonly GET_IGNORE_IP_PATTERNS = '/api/ignoreIpPatterns';

  readonly UPDATE_IGNORE_IP_PATTERNS = '/api/updateIgnoreIpPatterns';

  constructor(private authService: AuthenticationService,
              private currentUserService: CurrentUserService

  ) {
  }

  getRemoteIpAddress(): Observable<any> {
    const options = this.authService.getDefaultReqOptions();
    options.responseType = 'text';
    const url = AppConfig.UAA_URL + this.GET_REMOTE_IP_ADDRESS + '/' + this.currentUserService.getUserId();
    return this.authService.get( url, options );
  }

  getIgnoreIpPatterns(): Observable<any> {
    const options = this.authService.getDefaultReqOptions();
    options.responseType = 'text';
    const url = AppConfig.UAA_URL + this.GET_IGNORE_IP_PATTERNS + '/' + this.currentUserService.getUserId();
    return this.authService.get(url, options );
  }

  updateIgnoreIpPatterns(value): Observable<any> {
    const url = AppConfig.UAA_URL + this.UPDATE_IGNORE_IP_PATTERNS + '/' + this.currentUserService.getUserId();
    return this.authService.post(url, value);
  }

}
