import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SelectedDateService } from '../../services/seleteddate.service';

@Component({
  selector: 'app-datepicker',
  templateUrl: './app-datepicker.component.html',
  styleUrl: '/app-datepicker.component.css'
})

export class AppDatepickerComponent implements OnInit {

  @ViewChild('datePickerOne') datePicker: MatDatepicker<Date>

  // public myDatePickerOptions: IAngularMyDpOptions = {
  //   dateRange: false,
  //   dateFormat: 'yyyy-mm-dd',
  //   showSelectorArrow: true,
  // };

  public inputText: string = '';

  constructor(private selectedDayService: SelectedDateService) {
  }

  ngOnInit(): void {
    console.log('onInit(): DatePickerInline');
    // this.model = {isRange: false, singleDate: {jsDate: new Date()}};
  }


  onDateChanged( value ): void {
    console.log( 'value', value );
    // this.model.singleDate.jsDate.setDate( event.value.getDate() );
    // this.model.singleDate.jsDate.setMonth( event.value.getMonth() );
    // this.model.singleDate.jsDate.setFullYear( event.value.getFullYear() );
    this.save();
    // this.selectedDayService.notifyManualChange(this.model.singleDate.jsDate);
  }

  private save() {
    // this.selectedDayService.setDay(this.model.singleDate.jsDate.getDate());
    // this.selectedDayService.setMonth(this.model.singleDate.jsDate.getMonth() + 1);
    // this.selectedDayService.setYear(this.model.singleDate.jsDate.getFullYear());
    console.log( this.selectedDayService.getFormattedDate() )
  }



}
