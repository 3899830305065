import { Injectable, OnInit } from '@angular/core';
import { map, of, BehaviorSubject, catchError, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MapsService implements OnInit {

  private apiLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public apiLoaded$ = this.apiLoaded.asObservable();

  constructor(
              private http: HttpClient
  ) {

    this.http.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyAt0ljtoeqHvWlw0WgPd1AuIwGrEyrnK7c', 'callback')
    .pipe(
      map( () => true ),
      catchError( () => of(false) )
    ).subscribe( loaded => {
      this.apiLoaded.next(loaded);
    });
  }
  
  ngOnInit(): void {
  }

}

// just an interface for type safety.
interface IMarker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  title: string;
  www: string;
}

