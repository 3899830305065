import {Component} from '@angular/core';

@Component({
  selector: 'app-sidebar-nav-title',
  templateUrl: './app-sidebar-nav-title.component.html'
})
export class AppSidebarNavTitleComponent {


}
