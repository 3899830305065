import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProjectService} from '../../services/project.service';
import {Title} from '@angular/platform-browser';
import {LicenseService} from '../license';
import {Subscription} from 'rxjs/internal/Subscription';
import {ColumnMode} from "@swimlane/ngx-datatable";
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  templateUrl: './websites.component.html',
  providers: []
})
export class WebsitesComponent implements OnInit, OnDestroy {

  data = [];

  columns = [
    { name: 'id', sortable: true },
    { name: 'url' },
  ];

  columnMode = ColumnMode.standard;

  public proLicense: boolean;
  public addingNewWebsiteAllowed: boolean = true;

  private licenseValidSubscription: Subscription;
  // private addingWebsiteAllowedSubscription: Subscription;
  private projectsSubscription: Subscription;

  // private isAddingNewWebsiteAllowedSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  // private isAddingNewWebsiteAllowedObservable = this.isAddingNewWebsiteAllowedSubject.asObservable();

  constructor(
    private projectService: ProjectService,
    private title: Title,
    private licenseService: LicenseService,
    private notificationService: NotificationService
  ) {

  }
  
  ngOnInit(): void {
    
    this.title.setTitle('ExtraWatch Your Websites');

    this.projectsSubscription = this.projectService.getProjects().subscribe({
      next: (data) => { 
        this.data = data 
      }
    });

    this.licenseValidSubscription = this.licenseService.isLicenseValid()
      .subscribe( data => {
        this.proLicense = data;
      });
    // setTimeout(() => this.initAddingWebsiteAllowed(), 2000);

    this.licenseService.isAddingNewWebsiteAllowed().subscribe(
      (ok) => {
        this.addingNewWebsiteAllowed = ok;
      }
    );
  }

  // private initAddingWebsiteAllowed() {
  //   this.addingWebsiteAllowedSubscription = this.licenseService.isAddingNewWebsiteAllowed()
  //     .subscribe( data => {
  //         console.log('isAddingNewWebsiteAllowed: ' + data);
  //         this.isAddingNewWebsiteAllowedSubject.next(data);
  //       }
  //     );
  // }

  delete(projectId: string) {
    if (!confirm('Do you really want to delete?')) {
      return;
    }
    console.log('deleting project: ' + projectId);
    this.projectService.deleteProject(projectId);
    // setTimeout(() => this.initAddingWebsiteAllowed(), 2000);

    this.notificationService.displayToastrMesage( 'Websites', 'Deleted ' + projectId, true );
  }

  ngOnDestroy(): void {
    this.licenseValidSubscription?.unsubscribe();
    // this.addingWebsiteAllowedSubscription?.unsubscribe();
    this.projectsSubscription?.unsubscribe();
  }



}
