import {Injectable, OnDestroy} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {CurrentUserService} from '../../services/currentuser.service';
import {Observable, map} from 'rxjs';
import {combineLatestWith} from 'rxjs/operators';
import {AppConfig} from '../../app.config';
import {ProjectService} from '../../services/project.service';

@Injectable({
  providedIn: 'root',
})
export class LicenseService implements OnDestroy {

  readonly GET_LICENSE_VALIDITY = '/api/getLicenseValidity/';

  readonly GET_PURCHASE_HISTORY = '/api/getPurchaseHistory/';

  readonly IS_LICENSE_VALID = '/api/isLicenseValid';

  // private licenseIsValid$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private authService: AuthenticationService,
              private currentUserService: CurrentUserService,
              private projectService: ProjectService
  ) {
  }

  public isLicenseValid(): Observable<boolean> {
    return this.authService.post(AppConfig.UAA_URL + this.IS_LICENSE_VALID, 
      this.currentUserService.getLogin());
  }

  public getPurchaseHistory(): Observable<any> {
    return this.authService.get(AppConfig.UAA_URL + this.GET_PURCHASE_HISTORY + this.currentUserService.getUserId());
  }

  isAddingNewWebsiteAllowed(): Observable<boolean> {
    return this.projectService.getProjects().pipe(
      // zero no. of projects is ok
      map( data => ( data.length === 0 ) ),
      combineLatestWith( this.isLicenseValid() ),
      map( ([pok, lok]) => {
        // console.log( 'pok', pok, lok );
        return pok || lok;
      })
    );
  }

  ngOnDestroy(): void {
    console.log('cleanup');
    // if (this.licenseValidSubscription != null) {
    //   this.licenseValidSubscription.unsubscribe();
    // }
  }

}
