import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import {CurrentUserService} from './currentuser.service';
import {AppConfig} from '../app.config';
import {Observable, firstValueFrom, catchError, throwError } from 'rxjs';
import { Buffer } from 'buffer';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {

  public static AUTH_ENDPOINT = '/oauth/token';
  public static LOGIN_NOTIF_ENDPOINT = '/api/login-success';
  public static USERID_ENDPOINT = '/api/user';

  private isLoginSuccess = false;

  private subscription;

  constructor(
    private http: HttpClient,
    private router: Router,
    private currentUserService: CurrentUserService
  ) { }

  async login( username: string, password: string ): Promise<Boolean> {

    if ( ( username == null ) || ( password == null ) ) {
      username = this.currentUserService.getLogin() || '';
      password = this.currentUserService.getPassword() || '';
    }

    username = username?.trim();
    password = password?.trim();

    if ( !username || !password ) {
      return null;
    }

    const token = Buffer.from(username + ':' + password).toString('base64');

    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + token,
      })
    };

    const requestBody = this.serializeObj({
      client_id: username,
      username: username,
      password: password,
      grant_type: 'client_credentials'
    });

    const response = await firstValueFrom( this.http.post(
      AppConfig.UAA_URL + AuthenticationService.AUTH_ENDPOINT,
      requestBody,
      httpOptions
    ) ).then();

    this.isLoginSuccess = false;

    // success
    if ( response != null ) {
      const acToken = response['access_token']
      if ( acToken != null ) {
        this.currentUserService.setToken( acToken );
        this.currentUserService.setLogin( username );
        this.currentUserService.setPassword( password );
        this.isLoginSuccess = true;
        console.log( 'logged in successfully ');
      }
    }

    return this.isLoginSuccess;
  }

  public isAuthorized(): boolean {
    return Boolean( this.currentUserService.getToken() );
  }

  // public loginWithCredentialsFromStorage(): Observable<any> {
  //   const loginFromStorage = this.currentUserService.getLogin();
  //   const passwordFromStorage = this.currentUserService.getPassword();
  //   if (loginFromStorage && passwordFromStorage) {
  //     this.login(loginFromStorage, passwordFromStorage).then();
  //   }
  //   return EMPTY;
  // }

  public notifySuccessfulLogin( username: string ) {

    const requestBody = JSON.stringify({email: username});

    const reqOptions = this.getDefaultReqOptions();
    reqOptions.headers = reqOptions.headers.set( 'Accept', ACCEPT.PLAIN )
    reqOptions.responseType = 'text'

    let sub = this.post(
      AppConfig.UAA_URL + AuthenticationService.LOGIN_NOTIF_ENDPOINT,
      requestBody,
      reqOptions as Object
    ).subscribe({
      next: (userId) => {
        console.log( 'logged in as ' + userId );
      },
      error: (error) => {
        console.log( 'cannot log in ' );
      }
    });
  }

  public async findUserIdFromEmail(email: string): Promise<string> {

    const reqOptions = this.getDefaultReqOptions();
    reqOptions.responseType = 'text'

    return await firstValueFrom( this.post( 
      AppConfig.UAA_URL + AuthenticationService.USERID_ENDPOINT, 
      email, 
      reqOptions as Object
    ) );
  }

  public async setUserIdFromUsername(username: string) {
    this.findUserIdFromEmail(username).then(
      (userId) => {
        this.currentUserService.setUserId( userId );
      }
    );
  }

  public getDefaultReqOptions(): ReqOptionsType {
    return { headers: new HttpHeaders({
      'Content-Type': CTYPE.JSON,
      'Accept': ACCEPT.JSON })
    };
  }

  private modifyHeaders( req: ReqOptionsType ) {

    const token: string = this.currentUserService.getToken();
    
    if (token) {
      req.headers = req.headers.set('Authorization', 'Bearer ' + token);
    } else {
      this.router.navigate(['/pages/login']);
      return;
    }

  }

  private checkErrorResponse( error: HttpErrorResponse ) {
    if (error.status === 401) { // HTTP Status unauthorized
      this.login( null, null );
    } else if (error.status === 419) {
      this.login( null, null );
    } else {
      return throwError(() => new Error(error.message))
    }
  }

  get<T>(url: string, options?: ReqOptionsType ): Observable<T> {

    if ( options == null ) {
      options = this.getDefaultReqOptions();
    }

    this.modifyHeaders( options );

    return this.http.get<T>( url, options as Object ).pipe(
      catchError( error => this.checkErrorResponse(error) )
    );
  }

  post<T>(url: string, body: string, options?: ReqOptionsType ): Observable<T> {

    if ( options == null ) {
      options = this.getDefaultReqOptions();
    }
    
    this.modifyHeaders( options );

    return this.http.post<T>(url, body, options as Object ).pipe(
      catchError( error => this.checkErrorResponse(error) )
    );
  }

  postUrlEncoded<T>(url: string, body: string): Observable<T> {
    const options = this.getDefaultReqOptions();
    options.headers = options.headers.set( 'Content-Type', CTYPE.FORM );
    return this.post( url, body, options );
  }

  put<T>(url: string, body: string): Observable<T> {
    const options = this.getDefaultReqOptions();
    this.modifyHeaders( options );

    return this.http.put<T>(url, body, options as Object ).pipe(
      catchError( error => this.checkErrorResponse(error) )
    );
  }

  delete<T>( url: string, options?: ReqOptionsType ): Observable<T> {
    if ( options == null ) {
      options = this.getDefaultReqOptions();
    }
    this.modifyHeaders( options );

    return this.http.delete<T>(url, options as Object ).pipe(
      catchError( error => this.checkErrorResponse(error) )
    );
  }

  patch<T>(url: string, body: string): Observable<T> {
    const options = this.getDefaultReqOptions();
    this.modifyHeaders( options );

    return this.http.patch<T>(url, body, options as Object ).pipe(
      catchError( error => this.checkErrorResponse(error) )
    );
  }

  // postUrlEncoded<T>(url: string, body: string): Observable<T> {
  //   return this.http.post<T>(url, body, {headers: new HttpHeaders({
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     })
  //   });
  // }

  serializeObj(obj) {
    let result = [];
    for (var property in obj)
      result.push(encodeURIComponent(property) + '=' + encodeURIComponent(obj[property]));

    return result.join('&');
  }

  // private httpOptionsResponseTypeText = {
  //   headers: new HttpHeaders({
  //     'Accept': 'text/html, application/xhtml+xml, */*',
  //     'Content-Type': 'application/x-www-form-urlencoded'
  //   }),
  //   responseType: 'text'
  // };

  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }
}

export enum CTYPE {
  JSON = 'application/json; charset=utf-8', 
  FORM = 'application/x-www-form-urlencoded; charset=utf-8'
}

export enum ACCEPT {
  PLAIN = 'text/plain',
  JSON = 'application/json'
}

export type ReqOptionsType = {
  headers?: HttpHeaders,
  responseType?: string
}
