import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ProjectService } from '../../services/project.service';
import { LicenseService } from '../license';
import { first, firstValueFrom } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  templateUrl: 'website-add.component.html'
})
export class WebsiteAddComponent implements OnInit, OnDestroy {

  infoMessage: String;
  errorMessage: String;
  url: String;

  private subscription;
  private licenseValidSubscription;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private licenseService: LicenseService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.url = 'https://';
  }

  public async submit() {
    // console.log('submitted: ' +  this.url);

    const allowed$ = await firstValueFrom( this.licenseService.isAddingNewWebsiteAllowed() );
    if ( ! allowed$ ) {
      this.errorMessage = 'New website was not added, license is not active';
      return;
    }

    console.log( 'allowed' );
    await this.projectService.addProject(this.url);

    this.notificationService.displayToastrMesage('Websites',
      'Added ' + this.url, true);
  
    console.log('now');
    this.infoMessage = 'New website added successfully';
    this.router.navigate(['websites']);

  }

  // private addProject() {
  //   this.projectService.addProject(this.url);
  // }

  ngOnDestroy(): void {
    if (this.licenseValidSubscription != null) {
      this.licenseValidSubscription.unsubscribe();
    }
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

}
