<footer class="app-footer">
  <span><a href="https://www.extrawatch.com">ExtraWatch</a> &copy; {{ actualDate | date:'yyyy' }} CodeGravity.
  This product includes GeoLite2 data created by MaxMind, available from <a href="https://www.maxmind.com">https://www.maxmind.com</a>.
</span>
  <div>
    <ngx-spinner bdColor="rgba(51,51,51,0.0)" size="medium" color="#555" type="ball-spin-clockwise" [fullScreen]="false">
    </ngx-spinner>
  </div>

</footer>
