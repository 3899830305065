import {BadgeEnum} from './services/badge/badge.enum';

export const navigation = [
  {
    name: 'Visitors',
    url: '/visitors',
    icon: 'icon-user',
    badge: {
      type: BadgeEnum.PAGE_LOADS,
      variant: 'danger',
      text: ''
    },
  },
  {
    name: 'Click Heatmap',
    url: '/heatmap',
    icon: 'icon-fire',
    badge: {
      type: BadgeEnum.HEATMAP,
      variant: 'danger',
      text: ''
    }
  },
  {
    name: 'Map',
    url: '/map',
    icon: 'icon-map',
  },
  {
    name: 'Stats',
    url: '/stats',
    icon: 'icon-graph',
    badge: {
      type: BadgeEnum.UNIQUE,
      variant: 'danger',
      text: ''
    }
  },
  {
    name: 'Bots',
    url: '/bots',
    icon: 'icon-ghost'
    ,
  },
  {
    name: 'Google Traffic',
    url: '/organic',
    icon: 'icon-magnifier',
    badge: {
      type: BadgeEnum.ORGANIC,
      variant: 'danger',
      text: ''
    }
  },
  {
    name: 'Paths',
    url: '/pages',
    icon: 'icon-event',
    badge: {
      type: BadgeEnum.PATH,
      variant: 'danger',
      text: '15'
    },
  },
  {
    name: 'Page Titles',
    url: '/titles',
    icon: 'icon-book-open',
    badge: {
      type: BadgeEnum.TITLE,
      variant: 'danger',
      text: ''
    }
  },
  {
    name: 'Referrers',
    url: '/referrers',
    icon: 'icon-arrow-right-circle',
    badge: {
      type: BadgeEnum.REFERRER,
      variant: 'danger',
      text: ''
    }
  },
  {
    name: 'License',
    url: '/license',
    icon: 'icon-badge',
  },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'icon-settings',
    new: true
  },
  {
    name: 'Websites',
    url: '/websites',
    icon: 'icon-globe',
  },
  {
    name: 'Logout',
    url: '/pages/logout',
    icon: 'icon-logout',
  },
];
