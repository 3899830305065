import {AfterViewInit, Component, Input, OnDestroy} from '@angular/core';
import {AppGraphService} from './app-graph.service';
import {CurrentUserService} from '../../services/currentuser.service';
import {Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {ChartOptions} from './chart.options';
import {ProjectService} from '../../services/project.service';
import {VisitorService} from '../../views/visitors/visitor.service';


@Component({
  selector: 'app-graph',
  templateUrl: 'app-graph.component.html'
})
export class AppGraphComponent implements OnDestroy, AfterViewInit {

  public chartData: Array<any> = [
    {
      data: [],
      label: '',
      backgroundColor: 'rgba(255,255,255,.1)',
      borderColor: 'rgba(255,255,255,.55)',
      pointHoverBackgroundColor: '#fff'
    }
  ];

  public chartLabels: Array<any> = [];

  constructor(
    private sourcesService: AppGraphService,
    private currentUserService: CurrentUserService,
    private projectService: ProjectService,
    private visitorService: VisitorService
  ) {
  }
  readonly hideOnLowResolutionStyleClass = 'hideOnLowResolution';
  readonly dateFormat = 'yyyy-MM-dd';
  readonly datePipe = new DatePipe('en-US');

  private formattedDayToday =  this.datePipe.transform(new Date(), this.dateFormat);
  private formattedDayYesterday =  this.datePipe.transform( new Date().setDate((new Date().getDate() - 1)), this.dateFormat);
  private formattedDayYesterdayWeekAgo =  this.datePipe.transform( new Date().setDate((new Date().getDate() - 1 - 7)), this.dateFormat);

  @Input()
  cardStyleClass;

  @Input()
  iconStyleClass;

  @Input()
  private endpointName;

  countToday;
  countYesterday;
  countYesterdayWeekAgo;
  hideStyleClass = this.hideOnLowResolutionStyleClass;
  weeklyDifferenceRatio;

  private subscription: Subscription;
  private switchProjectSubscription: Subscription;
  private visitorServiceSubscription: Subscription;

  chartOptions = ChartOptions.chartOptions;
  // chartColours = ChartOptions.chartColours;
  chartLegend = ChartOptions.chartLegend;
  chartType = ChartOptions.chartType;


  ngAfterViewInit() {
    // this.load();

    this.switchProjectSubscription = this.projectService.getSelectedProjectId()
      .subscribe( projectId => {
        if ( projectId ) {
          this.load(projectId) 
        }
      });

    // this.visitorServiceSubscription = this.visitorService.visitors.subscribe(
    //   data => this.load());

  }

  private load( projectId: string ) {

    this.subscription = this.sourcesService.getFromSocialMediaByDay(projectId, this.endpointName)
      .subscribe({
        next: (result) => {

          result.reverse();

          const days = [];
          const counts = [];

          for (let i = 0; i < result.length; i++) {
            const day = result[i]['day'];
            days.push(day);
            const count = Number.parseInt(result[i]['count'], 0);
            counts.push(count);
            if (day === this.formattedDayYesterday) {
              this.countYesterday = count;
            }
            if (day === this.formattedDayToday) {
              this.countToday = count;
            }
            if (day === this.formattedDayYesterdayWeekAgo) {
              this.countYesterdayWeekAgo = count;
            }

          }

          if (this.countToday && this.countYesterdayWeekAgo) {
            const ratio = ((this.countToday - this.countYesterdayWeekAgo) / this.countYesterdayWeekAgo);
            if (ratio) {
              this.weeklyDifferenceRatio = ratio.toFixed(1);
            }
          }

          this.chartData[0].data = counts;
          this.chartLabels = days;

          this.hideStyleClass = !this.countToday ? this.hideOnLowResolutionStyleClass : '';

        }

      });


  }

  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
    if (this.switchProjectSubscription != null) {
      this.switchProjectSubscription.unsubscribe();
    }
    if (this.visitorServiceSubscription != null) {
      this.visitorServiceSubscription.unsubscribe();
    }
  }


}
