import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import {appRxStompConfig} from '../config/RxStompConfig';

@Injectable({
  providedIn: 'root',
})
export class RxStompService extends RxStomp {
  constructor() {
    super();
  }
}

export function rxStompServiceFactory() {
    const rxStomp = new RxStompService();
    rxStomp.configure(appRxStompConfig);
    rxStomp.activate();
    return rxStomp;
  }
