<header class="app-header navbar">

  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler d-md-down-none" type="button" appSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button>

  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right"></li>
    
    <!-- div class="table-responsive-mds">
      <table class="table">
        <tr>
          <td style="padding-top: 15px;">
            <app-license-component></app-license-component>
          </td>
          <td style="width:180px">
            <app-datepicker></app-datepicker>
          </td>
            <td>
              <app-project-selector></app-project-selector>
            </td>
        </tr>
      </table>
    </div -->

    <li class="nav-item dropdown" dropdown placement="bottom right">
      <app-license-component></app-license-component>
    </li>
    <!--
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <app-datepicker></app-datepicker>
    </li>
    -->
    <li class="nav-item dropdown" dropdown placement="bottom right">
        <app-project-selector></app-project-selector>
    </li>
      
   </ul>

</header>
