export class CountByDay {

  public day: string;
  public count: number;
  public timestamp: number;

  constructor(day: string, count: number) {

    this.day = day;
    this.count = count;

    let parts = day.split('-');
    this.timestamp = new Date( Number(parts[0]), Number(parts[1])-1, Number(parts[2])).getTime();
  }
}
