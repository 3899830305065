import {Injectable, OnDestroy} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthenticationService} from '../../services/authentication.service';
import {AppConfig} from '../../app.config';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {catchError, retry} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {throwError} from 'rxjs/internal/observable/throwError';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subscription} from 'rxjs/internal/Subscription';

@Injectable()
export class VisitorService implements OnDestroy {

  private static RETRIES = 10;

  private _visitorsSubject: ReplaySubject<any>;
  private _visitors;
  private dataSubscription: Subscription;

  constructor(
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
  ) {
    this._visitors = [];
    this._visitorsSubject = <ReplaySubject<any>> new ReplaySubject();

  }

  loadAll(projectId) {
    const url = AppConfig.BASE_URL + AppConfig.VISITOR_ENDPOINT;

    if (!projectId) {
      return;
    }

    this.spinner.show();
    setTimeout(() => this.spinner.hide(), 5000);
    this.dataSubscription = this.authService.get(url + projectId)
      .pipe(
        retry(VisitorService.RETRIES),
        catchError(this.handleError)
      )
      .subscribe({
        next: (data) => {
          if (data != null) {
            this._visitorsSubject.next(data);
          } else {
            this._visitorsSubject.next([]);
          }
          this.spinner.hide();
        }, 
        error: (error) => {
          console.error('could not load visitors: ' + error);
          this.spinner.hide();
        }
      });
  }

  export(projectId): Observable<any> {
    const url = AppConfig.BASE_URL + AppConfig.VISITOR_CSV_ENDPOINT;
    return this.authService.get(url + projectId, {responseType: 'application/csv'});
  }

  get visitors() {
    return this._visitorsSubject.asObservable();
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError (() =>
      'Something bad happened; please try again later.');
  }

}

