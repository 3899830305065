import {Inject, Injectable, OnInit} from '@angular/core';
import {SESSION_STORAGE, StorageService} from 'ngx-webstorage-service';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import {DatePipe} from '@angular/common';

@Injectable()
export class SelectedDateService implements OnInit {

  readonly DAY_KEY = 'dateselector_day';
  readonly DAY_MONTH = 'dateselector_month';
  readonly DAY_YEAR = 'dateselector_year';

  readonly DATE_FORMAT = 'yyyy-MM-dd';

  private datePipe = new DatePipe('en-US');

  public selectedDateSubject = new Subject<Date>();
  public selectedDateObservable  = this.selectedDateSubject.asObservable();

  constructor(
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) {
  }

  ngOnInit(): void {
    //this.selectedDateSubject = new ReplaySubject<any>([]);
  }


  public setDay(value) {
    this.storage.set(this.DAY_KEY, value);
  }

  public setMonth(value) {
    this.storage.set(this.DAY_MONTH, value);
  }

  public setYear(value) {
    this.storage.set(this.DAY_YEAR, value);
  }



  public getFormattedDate() {

    const day = this.storage.get(this.DAY_KEY);
    const month = this.storage.get(this.DAY_MONTH) - 1;
    const year = this.storage.get(this.DAY_YEAR);

    const date = new Date();
    if (day !== undefined && !isNaN(day) && month !== undefined && !isNaN(month) && year !== undefined && !isNaN(year)) {
      date.setFullYear(year, month, day);
    }

    return this.datePipe.transform(date, this.DATE_FORMAT);
  }

  public notifyManualChange(date: Date) {
    this.selectedDateSubject.next(date);
  }

}
