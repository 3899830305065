import {Component, Input} from '@angular/core';
import {Observable, EMPTY} from 'rxjs';
import {BadgeEnum} from '../../services/badge/badge.enum';
import {BadgeService} from '../../services/badge/badge.service';

@Component({
  selector: 'app-sidebar-nav-link',
  templateUrl: './app-sidebar-nav-link.component.html'
})
export class AppSidebarNavLinkComponent {

  @Input() link: any;

  public hasVariant() {
    return this.link.variant ? true : false
  }

  public isBadge() {
    return this.link.badge ? true : false
  }

  public isExternalLink() {
    return this.link.url.substring(0, 4) === 'http' ? true : false
  }

  public isIcon() {
    return this.link.icon ? true : false
  }

  public isNew() {
    return this.link.new ? true : false
  }


  constructor(private _badgeService: BadgeService) {
  }

  findSubjectForBadgeEnum(badgeEnum: BadgeEnum): Observable<any> {
    const newVar = this._badgeService.findSubjectByBadgeEnum(badgeEnum);
    if (!newVar) {
      return EMPTY;
    }
    return newVar.asObservable();
  }


  ngOnInit(): void {
  }


}
