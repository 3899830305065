import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {LicenseComponent} from './license.component';

@NgModule({
  imports:      [
    FormsModule,
    CommonModule,
    NgSelectModule,
    NgxDatatableModule,
    HttpClientModule
  ],
  declarations: [  ],
  exports: [  ],
  providers: [ HttpClient],
  bootstrap: [LicenseComponent]
})

export class LicenseModule { }


