
<div [class]="hideStyleClass">
<div class="brand-card">
  <div [class]="cardStyleClass">
    <i [class]="iconStyleClass"></i>
    <div class="chart-wrapper">
      <canvas baseChart class="chart"
              [datasets]="chartData"
              [labels]="chartLabels"
              [options]="chartOptions"
              [legend]="chartLegend"
              [type]="chartType"
      ></canvas>
    </div>
  </div>
  <!--<div class="brand-card-body">
    <div>
      <div class="text-value" title="yesterday">{{countYesterday ? countYesterday : 0}}</div>
    </div>
    <div>
      <div class="text-value" title="today">{{countToday ? countToday : 0}}</div>
    </div>
    <div>
      <div class="text-value" title="weekly difference">{{weeklyDifferenceRatio > 0 ? '+': ''}}{{weeklyDifferenceRatio ? weeklyDifferenceRatio + "%" : "N/A"}}</div>
    </div>
  </div>-->
</div>
</div>
