import {ToastrService} from 'ngx-toastr';
import {Injectable, OnDestroy, OnInit, SecurityContext} from '@angular/core';
import {CurrentUserService} from './currentuser.service';
import {DomSanitizer} from '@angular/platform-browser';
import {AudioService} from './audio.service';
import { BehaviorSubject } from 'rxjs';
import { RxStompService } from './rx-stomp.service';
import { IMessage } from '@stomp/rx-stomp';

export class Event {
  public type: string;
  public title: string;
  public message: string;
}


@Injectable()
export class NotificationService implements OnInit, OnDestroy {

  private notificationSubscription;
  private liveNotifications$ = new BehaviorSubject(true);

  constructor(
    private rxStompService: RxStompService,
    private toastr: ToastrService,
    private currentUserService: CurrentUserService,
    private domSanitizer: DomSanitizer,
    private audioService: AudioService,
  ) {
  }

  ngOnInit() {
  }

  public connectToTopic(projectId, callback): any {
    
    this.rxStompService.configure( {
      connectHeaders: {
        Authorization: 'Bearer ' + this.currentUserService.getToken(),
        projectId: this.currentUserService.getSelectedProjectId()
      }
    } );

    this.notificationSubscription = this.rxStompService
      .watch( '/topic/extrawatch/' + projectId )
      .subscribe( (message: IMessage) => {
        if (this.currentUserService.getSelectedProjectId() === projectId ) {
          const event = this.parseEvent(message);
          this.displayToastrMesage( event.title, event.message, 
            (event.type && event.type === 'bot') );
          callback(event);
        }
      });
  }

  public getLiveNotifications(): boolean {
    return this.liveNotifications$.value;
  }

  public setLiveNotifications( v: boolean ) {
    this.liveNotifications$.next(v);
    this.toastr.info( ( v ? 'enabled' : 'disabled '), 'Live notifications' );
  }

  private parseEvent( message: IMessage ): Event {
    const textTruncated = this.removeNullEndChar(message['body']);
    try {
      const event = JSON.parse(textTruncated);
      event.message = this.domSanitizer.sanitize(SecurityContext.HTML, event.message);
      event.message = event.message.replace('\n', '<br/>');
      event.title = this.domSanitizer.sanitize(SecurityContext.HTML, event.title);
      return event;
    } catch (e) {
      console.log('could not parse: ' + textTruncated, e);
    }
  }

  public displayToastrMesage( title, message, info=false ) {

    if ( ! this.getLiveNotifications() ) {
      // console.log( this.getLiveNotifications() );
      return;
    }

    if ( info ) {
      this.toastr.info( message, title, {enableHtml: true});
    } else {
      this.toastr.success( message, title, {enableHtml: true});
    }
    this.audioService.playAudio();
  }

  public displayErrorMesage( title, message ) {
    this.toastr.error( message, title, {enableHtml: true});
  }

  private removeNullEndChar(text) {
    let textTruncated = text;
    if (text.charCodeAt(text.length - 1) === 0) {
      textTruncated = text.substring(0, text.length - 1);
    }
    return textTruncated;
  }

  public disconnect() {
    if (this.notificationSubscription != null) {
      this.notificationSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.disconnect();
  }


}
