import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { provideHttpClient, withJsonpSupport } from '@angular/common/http';
import {NgxSpinnerModule} from 'ngx-spinner';

import {AppComponent} from './app.component';
// Import containers
import {FullLayoutComponent, SimpleLayoutComponent} from './containers';
// Import components
import {AppFooterComponent, AppHeaderComponent, AppSidebarComponent, AppSidebarMinimizerComponent} from './components';
// Import directives
import {AsideToggleDirective, NAV_DROPDOWN_DIRECTIVES, ReplaceDirective, SIDEBAR_TOGGLE_DIRECTIVES} from './directives';
// Import routing module
// Import 3rd party components
import {NgChartsModule, ThemeService} from 'ng2-charts';
// import {OAuth2Interceptor} from './services/OAuth2Interceptor';
import {CurrentUserService} from './services/currentuser.service';
import {NotificationService} from './services/notification.service';
import {ToastrModule, ToastrService} from 'ngx-toastr';
import {ToastConfig} from './services/toast.config';
import {ProjectService} from './services/project.service';
import {MapsService } from './services/maps.service';
import {FormsModule} from '@angular/forms';
import {AppProjectSelectorComponent} from './components/app-project-selector/app-project-selector.component';

import {NgSelectModule} from '@ng-select/ng-select';

import {AppDatepickerComponent} from './components/app-datepicker/app-datepicker.component';
import {SelectedDateService} from './services/seleteddate.service';
import {WebsiteAddComponent} from './views/websites';
import {ConsentComponent} from './components/consent';
import {ConsentService} from './services/consent.service';
import {AuthenticationService} from './services/authentication.service';
import {CryptoService} from './services/crypto.service';
import {BadgeService} from './services/badge/badge.service';
import {BadgeValueService} from './services/badge/badge.value.service';
import {StatService} from './views/stats/stat.service';
import {AppSidebarNavComponent} from './components/app-sidebar-nav/app-sidebar-nav.component';
import {AppSidebarNavLinkComponent} from './components/app-sidebar-nav-link';
import {AppSidebarNavItemComponent} from './components/app-sidebar-nav-item';
import {AppSidebarNavTitleComponent} from './components/app-sidebar-nav-title';
import {DataFilterPipeModule} from './directives/datafilter';
import {LicenseService} from './views/license/license.service';
import {LicenseComponent} from './views/license';
import {SettingsService} from './services/settings.service';
import {RxStompService, rxStompServiceFactory } from './services/rx-stomp.service';
import {AudioService} from './services/audio.service';
import {MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {RouterModule, Routes} from "@angular/router";

const APP_CONTAINERS = [
  FullLayoutComponent,
  SimpleLayoutComponent
];

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'pages/login',
    pathMatch: 'full',
  },
  {
    path: 'consent',
    component: ConsentComponent,
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'websites',
        children: [
          {
            path: '',
            loadChildren: () => import('./views/websites/websites.module').then(m => m.WebsitesModule)
          },
          {
            path: 'add',
            component: WebsiteAddComponent,
          }

        ]
      },
      {
        path: 'visitors',
        loadChildren: () => import('./views/visitors/visitors.module').then(m => m.VisitorsModule)
      },
      {
        path: 'stats',
        loadChildren: () => import('./views/stats/stats.module').then(m => m.StatsModule)
      },
      {
        path: 'login',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'pages',
        loadChildren: () => import('./views/pages/paths.module').then(m => m.PathsModule)
      },
      {
        path: 'titles',
        loadChildren: () => import('./views/titles/titles.module').then(m => m.TitlesModule)
      },
      {
        path: 'referrers',
        loadChildren: () => import('./views/referrers/referrers.module').then(m => m.ReferrersModule)
      },
      {
        path: 'organic',
        loadChildren: () => import('./views/organic/organic.module').then(m => m.OrganicModule)
      },
      {
        path: 'map',
        loadChildren: () => import('./views/maps/maps.module').then(m => m.MapsModule)
      },
      {
        path: 'bots',
        loadChildren: () => import('./views/bots/bots.module').then(m => m.BotsModule)
      },
      {
        path: 'heatmap',
        loadChildren: () => import('./views/heatmap/heatmap.module').then(m => m.HeatmapModule)
      },
      {
        path: 'license',
        loadChildren: () => import('./views/purchase/purchase.module').then(m => m.PurchaseModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule)
      }
    ]
  },
  {
    path: 'pages',
    component: SimpleLayoutComponent,
    data: {
      title: 'Pages'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'login',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
      }
    ]
  }
];


const APP_COMPONENTS = [
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarNavComponent,
  AppSidebarNavLinkComponent,
  AppSidebarNavItemComponent,
  AppSidebarNavTitleComponent,
  AppSidebarMinimizerComponent,
  AppProjectSelectorComponent,
  AppDatepickerComponent,
  /*
    APP_SIDEBAR_NAV,
  */

  ConsentComponent,
  LicenseComponent
];


const APP_DIRECTIVES = [
  NAV_DROPDOWN_DIRECTIVES,
  AsideToggleDirective,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
];



@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    NgChartsModule,
    DataFilterPipeModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    NgSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES,
    WebsiteAddComponent
  ],
  providers: [
    CurrentUserService,
    NotificationService,
    ToastrService,
    ToastConfig,
    ProjectService,
    SelectedDateService,
    AuthenticationService,
    ConsentService,
    CryptoService,
    BadgeService,
    BadgeValueService,
    StatService,
    LicenseService,
    SettingsService,
    MapsService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: OAuth2Interceptor,
    //   multi: true,
    // },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
    },
    HttpClient,
    provideHttpClient(withJsonpSupport()),
    AudioService,
    ThemeService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
