import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService, CTYPE, ReqOptionsType } from './authentication.service';
import { AppConfig } from '../app.config';
import { CurrentUserService } from './currentuser.service';
import { NotificationService } from './notification.service';
import { Observable, Subscription, Subject, BehaviorSubject, firstValueFrom, lastValueFrom } from 'rxjs';
import { first, map, take, combineLatestWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectService implements OnDestroy {

  private projects: BehaviorSubject<Object[]> = new BehaviorSubject<Object[]>([]);
  private projects$ = this.projects.asObservable();
  private selectedProjectIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private selectedProjectId$ = this.selectedProjectIdSubject.asObservable();

  private readonly PROJECTS_BY_EMAIL = '/api/projects-by-email';
  private readonly PROJECTS = '/api/projects';

  constructor(
    private authService: AuthenticationService,
    private currentUserService: CurrentUserService
  ) {

    this.refreshProjects().then(
      () => {

        let presetProjectId = this.currentUserService.getSelectedProjectId();
        if (presetProjectId == null && this.projects.value) {
          presetProjectId = this.projects[0].id;
        }
    
        this.selectedProjectIdSubject.next( presetProjectId );
      }
    );

  }

  getProjects(): Observable<Object[]> {
    return this.projects$;
  }

  getSelectedProjectId(): Observable<string> {
    return this.selectedProjectId$;
  }

  private async refreshProjects() {
    const login = this.currentUserService.getLogin();
    const url = AppConfig.UAA_URL + this.PROJECTS_BY_EMAIL;
    const body = this.authService.serializeObj({ email: login });
    const options = this.authService.getDefaultReqOptions();
    options.headers = options.headers.set( 'Content-Type', CTYPE.FORM );

    const projects$ = await firstValueFrom( this.authService.post( url, body, options ) );
    
    this.projects.next( projects$ as Object[] );

    // const projects$ = this.restService.postUrlEncoded(url, body).pipe(take(1));
  }

  async addProject(projectUrl) {
    const login = this.currentUserService.getLogin();
    const url = AppConfig.UAA_URL + this.PROJECTS;
    const body = this.createAddProjectBody(projectUrl, login);

    const options = this.authService.getDefaultReqOptions();
    options.headers = options.headers.set( 'Content-Type', CTYPE.FORM );
    options.responseType = 'text';

    const projects$ = await firstValueFrom( this.authService.post( url, body, options ) );

    await this.refreshProjects();
  }

  private createAddProjectBody(projectUrl, login: string) {
    return this.authService.serializeObj({
      url: projectUrl,
      email: login,
      enabled: true,
      alwaysActive: true
    });
  }


  async deleteProject(projectId: string) {
    const userId = this.currentUserService.getUserId();
    const url = AppConfig.UAA_URL + this.PROJECTS + '?userId=' + userId + '&projectId=' + projectId;

    const options = this.authService.getDefaultReqOptions();
    options.headers = options.headers.set( 'Content-Type', CTYPE.FORM );
          
    const deleted = await firstValueFrom( this.authService.delete( url, options ) );

    await this.refreshProjects();
  }

  public switchProject(newProjectId: string) {
    this.currentUserService.setSelectedProjectId(newProjectId);
    this.selectedProjectIdSubject.next(newProjectId);
  }

  ngOnDestroy(): void {
  }

}
