import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {BadgeEnum} from './badge.enum';
import {AppConfig} from "../../app.config";
import {AuthenticationService} from "../authentication.service";

@Injectable()
export class BadgeService implements OnInit, OnDestroy {

  private _badgeSubjectMap: Map<BadgeEnum, ReplaySubject<any>> = new Map();

  constructor(private authService: AuthenticationService) {
    this.inializeSubjectMap();
  }

  public getVisitByDayBadge(projectId: string, day: string): Observable<any> {
    return this.authService.get(AppConfig.BASE_URL + AppConfig.VISIT_BY_DAY_BADGE_ENDPOINT + '/' + projectId + '?day=' + day );
  }


  public getUniqueByDayBadge(projectId: string, day: string): Observable<any> {
    const urlCounts = AppConfig.BASE_URL + AppConfig.UNIQUE_BY_DAY_BADGE_ENDPOINT + '/' + projectId + '?day=' + day;
    return this.authService.get(urlCounts);
  }

  public getOrganicByDayBadge(projectId: string, day: string): Observable<any> {
    const urlCounts = AppConfig.BASE_URL + AppConfig.ORGANIC_BY_DAY_BADGE_ENDPOINT  + '/' + projectId + '?day=' + day;
    return this.authService.get(urlCounts);
  }

  public getPathByDayBadge(projectId: string, day: string): Observable<any> {
    const urlCounts = AppConfig.BASE_URL + AppConfig.PATH_BY_DAY_BADGE_ENDPOINT  + '/' + projectId + '?day=' + day;
    return this.authService.get(urlCounts);
  }



  public getReferrerByDayBadge (projectId: string, day: string): Observable<any> {
    const urlCounts = AppConfig.BASE_URL + AppConfig.REFERRER_BY_DAY_BADGE_ENDPOINT  + '/' + projectId + '?day=' + day;
    return this.authService.get(urlCounts);
  }


  public getTitleByDayBadge(projectId: string, day: string): Observable<any> {
    const urlCounts = AppConfig.BASE_URL + AppConfig.TITLE_BY_DAY_BADGE_ENDPOINT  + '/' + projectId + '?day=' + day;
    return this.authService.get(urlCounts);
  }



  public findSubjectByBadgeEnum(badgeEnum: BadgeEnum): ReplaySubject<any> {
    return this._badgeSubjectMap.get(badgeEnum);
  }

  public setBadgeValue(badgeEnum: BadgeEnum, value: any) {
    this._badgeSubjectMap.get(badgeEnum).next(value);
  }

  private get badgeSubjectMap(): Map<BadgeEnum, ReplaySubject<any>> {
    return this._badgeSubjectMap;
  }

  ngOnInit(): void {
  }

  private inializeSubjectMap() {
    const keys = Object.keys(BadgeEnum);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      this._badgeSubjectMap.set(BadgeEnum[key], new ReplaySubject<any>());
    }
  }


  ngOnDestroy(): void {
  }



}
