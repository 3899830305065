import {Injectable, OnDestroy} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {AppConfig} from '../../app.config';
import {Observable} from 'rxjs/internal/Observable';
import {CountByDay} from './count.by.day';
import {Subscription} from 'rxjs/internal/Subscription';
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {CurrentUserService} from "../../services/currentuser.service";
import { BadgeService } from 'src/app/services/badge/badge.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable()
export class StatService implements OnDestroy {


  private uniquesByDaySubject: BehaviorSubject<CountByDay[]> = new BehaviorSubject<CountByDay[]>([]);
  public uniquesByDay$ = this.uniquesByDaySubject.asObservable();

  private visitsByDaySubject: BehaviorSubject<CountByDay[]> = new BehaviorSubject<CountByDay[]>([]);
  public visitsByDay$ = this.visitsByDaySubject.asObservable();

  private visitsByDaySubscription: Subscription;
  private uniquesByDaySubscription: Subscription;

  constructor(
    private authService: AuthenticationService,
    private badgeService: BadgeService
  ) {
  }


  async refresh( projectId: string ) {

    const vm = new Map();

    const visits$ = await firstValueFrom( this.getVisitsByDay(projectId) );

    for ( let v of visits$ ) {
      const x = v.day.split('-');
      v.day = x[2]+'-'+x[1]+'-'+x[0];
      vm.set( v.day, v.count );
    }

    let um = new Map();

    const uniques$ = await firstValueFrom( this.getUniquesByDay(projectId) );

    for ( let u of uniques$ ) {
      const x = u.day.split('-');
      u.day = x[2]+'-'+x[1]+'-'+x[0];
      um.set( u.day, u.count );
    }

    let va = [];
    let ua = [];

    for ( let [d,cnt] of vm ) {
      va.push( new CountByDay(d,cnt) );
      ua.push( new CountByDay(d, um.get(d) || cnt ) );
    }

    this.visitsByDaySubject.next( va );
    this.uniquesByDaySubject.next( ua );
   
  }

  async loadMore( projectId: string ) {
    
    let curData = this.visitsByDaySubject.value;
    let newData = []
    let minTs = 0;

    for ( const cd of curData ){
      if ( minTs == 0 ) {
        minTs = cd.timestamp
      } else if ( minTs > cd.timestamp ) {
        minTs = cd.timestamp;
      }
    }

    let stop_ts = minTs - 86400000*10;
    for (let i = minTs-86400000 ; i >= stop_ts; i -= 86400000 ) {
      let d = new Date(i)
      let fmtDate = d.getFullYear()+'-'
        + new String(d.getMonth()+1).padStart(2,'0')
        +'-'+ new String(d.getDate()).padStart(2,'0');

      const visit$ = await firstValueFrom( this.badgeService.getVisitByDayBadge( 
        projectId, fmtDate ) );

      let v = 0;
      if (visit$.length > 0) {
        v = visit$[0];
      }
  
      newData.push( new CountByDay( fmtDate, v ) );
    }

    this.visitsByDaySubject.next( [...newData,...curData] );

    curData = this.uniquesByDaySubject.value;
    newData = []
    minTs = 0;

    for ( const cd of curData ){
      if ( minTs == 0 ) {
        minTs = cd.timestamp
      } else if ( minTs > cd.timestamp ) {
        minTs = cd.timestamp;
      }
    }

    // stop_ts = minTs - 86400000*5;
    for (let i = minTs-86400000 ; i >= stop_ts; i -= 86400000 ) {
      let d = new Date(i)
      let fmtDate = d.getFullYear()+'-'
        + new String(d.getMonth()+1).padStart(2,'0')
        +'-'+ new String(d.getDate()).padStart(2,'0');

      const visit$ = await firstValueFrom( this.badgeService.getUniqueByDayBadge( projectId, fmtDate ) );

      let v = 0;
      if ( visit$.length > 0 ) {
        v = visit$[0];
      }

      newData.push( new CountByDay( fmtDate, v ) );
    }

    this.uniquesByDaySubject.next( [...newData,...curData] );
  }


  public getUniquesByDay(projectId: string): Observable<any> {
    return this.authService.get(AppConfig.BASE_URL + AppConfig.UNIQUES_BY_DAY_ENDPOINT + '/' + projectId);
  }

  public getVisitsByDay(projectId: string): Observable<any> {
    return this.authService.get(AppConfig.BASE_URL + AppConfig.VISITS_BY_DAY_ENDPOINT + '/' + projectId);
  }

  public getTotalHeatmapCountByDay(projectId: string, date: string): Observable<any> {
    const urlCounts =   AppConfig.HEATMAP_URL + AppConfig.HEATMAP_BADGE_VALUE_ENDPOINT + projectId + '/' + date;
    return this.authService.get(urlCounts);
  }

  ngOnDestroy() {
    if (this.visitsByDaySubscription != null) {
      this.visitsByDaySubscription.unsubscribe();
    }
    if (this.uniquesByDaySubscription != null) {
      this.uniquesByDaySubscription.unsubscribe();
    }
  }

}

