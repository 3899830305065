<div class="app flex-row">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">

        <div class="card-group mb-4">
          <div class="card p-4">
            <div class="card-body">

              <div class="col-md-12 m-a-12">

                <div class="animated fadeIn">

                  <div class="row">

                    <div class="col-md-12 m-a-12">

                      <h2>Add new website to your account</h2>

		      You can add several websites and switch between them. <br/>
		      If you use Joomla / Wordpress plugin, make sure you that you entered the same project ID in plugin settings. <br/>

                      <span style="color: red">{{errorMessage}}</span>
                      <span style="color: green">{{infoMessage}}</span>

                      <br/><br/>
                      <div class="card">
                        <div class="card-body">
                          <form action="" method="post">


                            <div class="form-group">
                              <label for="nf-url">URL</label>
                              <input type="text" id="nf-url" name="nf-url"
                                     [(ngModel)]="url" class="form-control" placeholder="Enter Password..">
                            </div>

                          </form>
                        </div>
                        <div class="card-footer">
                          <button type="submit" class="btn btn-sm btn-primary" (click)="submit()" required><i class="fa fa-dot-circle-o"></i> Submit</button>
                        </div>

                      </div>
                      <br/>
                      <button type="button" class="btn btn-link px-0" onclick="history.go(-1)">&lt; Back</button>
                    </div>


                  </div>
                </div>

              </div>
            </div>



          </div>
        </div>
      </div>


    </div>
  </div>
</div>
