import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProjectService} from '../../services/project.service';
import {CurrentUserService} from '../../services/currentuser.service';
import { first, Subscription } from 'rxjs';


@Component({
  selector: 'app-project-selector',
  templateUrl: './app-project-selector.component.html',
})
export class AppProjectSelectorComponent implements OnInit, OnDestroy {

  projects = [];

  selectedId: any;

  private projects$: Subscription;
  private projectId$: Subscription;

  constructor(
    private projectService: ProjectService
  ) {
  }

  ngOnInit(): void {
    this.projects$ = this.projectService.getProjects()
      .subscribe({
        next: (projects) => {
          this.projects = projects;
          // this.switchToFirstProjectIfEmpty();
        }
      });
      
    this.projectId$ = this.projectService.getSelectedProjectId().
      subscribe({
        next: (projectId) => {
          // console.log( 'selected this', projectId );
          this.selectedId = projectId;
        }
    });
  }

  // private switchToFirstProjectIfEmpty() {
  //   const selectedProjectId = this.currentUserService.getSelectedProjectId();
  //   console.log('selecting project Id: ' + selectedProjectId);

  //   if (this.selectedProjectId == null) {

  //     if (selectedProjectId != null) {
  //       this.selectedProjectId = selectedProjectId;
  //       this.projectService.switchProject(selectedProjectId);
  //       return;
  //     }

  //     if (selectedProjectId == null && this.projects.length > 0) {
  //       const firstProjectId = this.projects[0].id;
  //       this.selectedProjectId = firstProjectId;
  //       this.projectService.switchProject(firstProjectId);
  //     }
  //   }
  // }

  onChange($event) {
    const newProjectId = $event.id;
    this.projectService.switchProject( newProjectId );
  }

  ngOnDestroy(): void {
    if (this.projectId$ != null) {
      this.projectId$.unsubscribe();
    }
    if ( this.projectId$ != null ) {
      this.projectId$.unsubscribe();
    }
  }

}
