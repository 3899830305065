import {Component, OnDestroy, OnInit} from '@angular/core';
import {LicenseService} from './license.service';

@Component({
  selector: 'app-license-component',
  templateUrl: './license.component.html'
})
export class LicenseComponent implements OnInit, OnDestroy {

  public licenseValid = false;
  private licenseSubscription;

  constructor(
    private licensingService: LicenseService
  ) {
  }

  ngOnInit(): void {
    this.licenseSubscription = this.licensingService.isLicenseValid()
    .subscribe({
      next: (data) => {
        this.licenseValid = data;
        console.log('license valid: ' + this.licenseValid);
      }, 
      error: (error) => console.log('error' + error)
    });
  }


  ngOnDestroy(): void {
    if (this.licenseSubscription != null) {
      this.licenseSubscription.unsubscribe();
    }

  }

}
