import * as CryptoJS from 'crypto-js';
import {Injectable} from '@angular/core';

@Injectable()
export class CryptoService  {

  private key = '>YhrdZ4Aw%4PD/,\\fnsn9,N8Zrh:+f).5"9Tx7\\"8Bz*SMPC;);.Bb?"\\Rf8\'qg/U7`L3KiY;Ln&eBh9hu/o7}*9R:fuonD_Z?@,ay^<vQ!KwceKN[,z_(.w`Zy\\zRyF';

  public encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.key).toString();

  }

  public decrypt(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.key);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }


}
