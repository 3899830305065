import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html'
})
export class AppFooterComponent implements OnInit {

  public actualDate;

  ngOnInit(): void {
    this.actualDate = new Date();
  }
}
