<div class="container-fluid">
  <div class="">

    <h2>Your websites</h2>
    Websites associated with your account. You can switch between these websites.

    <span *ngIf="addingNewWebsiteAllowed">
      <a href="/#/websites/add" >Add a website</a>
    </span>
    <span *ngIf="!addingNewWebsiteAllowed">
      <a href="/#/license" class="disabled" title="You are able to add multiple websites in PRO version.">Add a website</a>
    </span>

    <br/>

    <br/>
    <div class="panel panel-default" style="overflow-x:auto;">

      <ngx-datatable
        id="visitors"
        [rowHeight]="60"
        [rows]="data"
        [columns]="columns"
        class="bootstrap stripped"
        [columnMode]="columnMode"
      >
        <ngx-datatable-column
          [resizeable]="true"
          [sortable]="true"
          [draggable]="true"
          [canAutoResize]="true"
          name="id"
          prop="id"
          [width]="400"
        >
          <ng-template ngx-datatable-header-template>
            <span>Project ID</span>
          </ng-template>
          <ng-template
            let-row="row"
            class="hideOnLowResolution"
            rowClass="hideOnLowResolution"
            ngx-datatable-cell-template
          >
            {{ row.id }}
          </ng-template>

        </ngx-datatable-column>

        <ngx-datatable-column
          [resizeable]="true"
          [sortable]="true"
          [draggable]="true"
          [canAutoResize]="true"
          name="url"
          prop="url"
          [width]="400"
        >
          <ng-template ngx-datatable-header-template>
            <span>Website URL</span>
          </ng-template>
          <ng-template
            let-row="row"
            ngx-datatable-cell-template
          >
            {{ row.url }}
          </ng-template>

        </ngx-datatable-column>

        <ngx-datatable-column
          [resizeable]="true"
          [sortable]="true"
          [draggable]="true"
          [canAutoResize]="true"
          name="url"
          prop="url"
          [width]="60"
        >
          <ng-template ngx-datatable-header-template>
          </ng-template>
          <ng-template
            let-row="row"
            ngx-datatable-cell-template
          >
            <a (click)="delete(row.id)" title="Delete project" style="cursor: pointer;"><em class="fa fa-trash"></em></a>

          </ng-template>

        </ngx-datatable-column>


      </ngx-datatable>


      <hr/>

      <h2>Adding tracking code: </h2>

      <br/>
      <h3>No need to add tracking code manually when using Joomla or Wordpress plugin!</h3>
      When you're using plugins / extensions, it's added automatically. You just need to activate your account and website or specify an existing project ID in the settings.

      <br/>
      <br/>

      <h3>Setup in case you don't use any plugin</h3>
      If you want to monitor your website, make sure that the following HTML code snippet is included on your website.
      <br/>(It's there when you click in browser: "View Source").
      <br/>
      <br/>

      <textarea cols="80" rows="10">
&lt;script type="text/javascript"&gt;
var _extraWatchParams = _extraWatchParams || [];
_extraWatchParams.projectId = 'PROJECT_ID';
(function() {
        var ew = document.createElement('script'); ew.type = 'text/javascript'; ew.async = true;
        ew.src = 'https://agent.extrawatch.com/agent/js/ew.js';
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ew, s);
})();
&lt;/script&gt;
</textarea>

      <br/>
      <br/>
      "<strong>PROJECT_ID</strong>" needs to be replaced with the value of 'Project ID' listed in the table!


      <br/>
      <br/>

      <h4>Example:</h4>
      <br/>
      <textarea cols="80" rows="5">
 &lt;script type="text/javascript"&gt;
...
 _extraWatchParams.projectId = '04c94c10-b06d-431c-bc82-eb452eb7b209';
 ...
 &lt;/script&gt;
    </textarea>

    </div>
  </div>


</div>

