import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';

import {HttpClient, HttpClientModule} from '@angular/common/http';
import {WebsitesRoutingModule} from './websites-routing.module';
import {WebsitesComponent} from './websites.component';
import {ProjectService} from '../../services/project.service';
import {DataFilterPipeModule} from '../../directives/datafilter';

@NgModule({
  imports:      [
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    WebsitesRoutingModule,
    HttpClientModule,
    DataFilterPipeModule
  ],
  declarations: [ WebsitesComponent],
  exports: [WebsitesComponent],
  providers: [HttpClient, ProjectService],
  bootstrap: [WebsitesComponent]
})

export class WebsitesModule { }


