import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {DataFilterPipe} from './data-filter.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [
    DataFilterPipe
  ],
  providers: [
  ],
  exports: [DataFilterPipe]
})
export class DataFilterPipeModule { }
