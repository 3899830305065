export class AppConfig {

  /* production */
  public static BASE_URL = 'https://app.extrawatch.com';
  public static UAA_URL = AppConfig.BASE_URL + '/uaa';
  public static readonly STAT_APP_PREFIX = '/stat';

  /* local */
/*
  public static BASE_URL = 'http://localhost:8084';
  public static UAA_URL = AppConfig.BASE_URL + '/uaa';
  public static readonly STAT_APP_PREFIX = '';
*/


  // public static UAA_URL = 'https://app.extrawatch.com' + '/uaa';

  public static WS_URL = AppConfig.BASE_URL + '/ws/event';
  public static HEATMAP_URL = AppConfig.BASE_URL + '/heatmap';

  public static VISITOR_ENDPOINT = '/visitor/api/visits?projectId=';
  public static VISITOR_CSV_ENDPOINT = '/visitor/api/visits-csv?projectId=';
  public static BOT_ENDPOINT = '/visitor/api/bots?projectId=';
  public static LOCATION_ENDPOINT = '/visitor/api/visit-locations?projectId=';
  public static HEATMAP_ENDPOINT = '/api/read';
  public static readonly HEATMAP_BADGE_VALUE_ENDPOINT = '/api/count/';



  /* badge */
  public static readonly VISIT_BY_DAY_BADGE_ENDPOINT = AppConfig.STAT_APP_PREFIX + '/api/visit-by-day/badge';
  public static readonly UNIQUE_BY_DAY_BADGE_ENDPOINT = AppConfig.STAT_APP_PREFIX + '/api/unique-by-day/badge';
  public static readonly REFERRER_BY_DAY_BADGE_ENDPOINT = AppConfig.STAT_APP_PREFIX + '/api/referrer-by-day/badge';
  public static readonly TITLE_BY_DAY_BADGE_ENDPOINT = AppConfig.STAT_APP_PREFIX + '/api/title-by-day/badge';
  public static readonly ORGANIC_BY_DAY_BADGE_ENDPOINT = AppConfig.STAT_APP_PREFIX + '/api/organic-by-day/badge';
  public static readonly PATH_BY_DAY_BADGE_ENDPOINT = AppConfig.STAT_APP_PREFIX + '/api/path-by-day/badge';

  /* collections */
  public static readonly VISITS_BY_DAY_ENDPOINT = AppConfig.STAT_APP_PREFIX + '/api/visits-by-day';
  public static readonly UNIQUES_BY_DAY_ENDPOINT = AppConfig.STAT_APP_PREFIX + '/api/uniques-by-day';
  public static readonly ORGANICS_BY_DAY_ENDPOINT = AppConfig.STAT_APP_PREFIX  + '/api/organics-by-day';
  public static readonly TITLES_BY_DAY_ENDPOINT = AppConfig.STAT_APP_PREFIX  + '/api/titles-by-day';
  public static readonly REFERRERS_BY_DAY_ENDPOINT = AppConfig.STAT_APP_PREFIX  + '/api/referrers-by-day';
  public static readonly PATHS_BY_DAY_ENDPOINT = AppConfig.STAT_APP_PREFIX + '/api/paths-by-day';

  /* sum by day */
  public static SOCIALS_ENDPOINT_PREFIX = AppConfig.BASE_URL + AppConfig.STAT_APP_PREFIX + '/api/socials/';

}

