
export class ChartOptions {
  static chartOptions: any = {
    tooltips: {
      enabled: true,
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      'y-axes-0': {
        display: false
      }
    },
    elements: {
      line: {
        borderWidth: 2,
        tension: 0.5
      },
      point: {
        radius: 1,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      }
    },
    legend: {
      display: false
    }
  };

  // static chartColours: Array<any> = [{
  //     backgroundColor: 'rgba(255,255,255,.1)',
  //     borderColor: 'rgba(255,255,255,.55)',
  //     pointHoverBackgroundColor: '#fff'
  //   }
  // ];

  static chartLegend = false;
  static chartType = 'line';

}
