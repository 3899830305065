import {Injectable, OnDestroy} from '@angular/core';


@Injectable()
export class AudioService implements OnDestroy {

  private audio: HTMLAudioElement;

  private readonly soundFile = '../../assets/audio/click.mp3';

  constructor() {
  }

  playAudio(): Promise<void> {
    this.audio = new Audio();
    this.audio.src = this.soundFile;
    this.audio.load();
    return this.audio.play();
  }

  ngOnDestroy(): void {
    this.audio = null;
  }
}

