import {Inject, Injectable, OnInit, Optional} from '@angular/core';
import {CryptoService} from './crypto.service';
import {LOCAL_STORAGE, SESSION_STORAGE, StorageService} from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {

  private static TOKEN_KEY = '_token';

  private static LOGIN_KEY = '_login';

  private static PASSWORD_KEY = '_password';

  private static SELECTED_PROJECT_ID_KEY = '_selectedProjectId';

  private static USER_ID_KEY = '_userId';

  private _logoutPressed: boolean;

  constructor(

    @Inject(LOCAL_STORAGE)
    @Optional() private localStorage: StorageService,

    @Inject(SESSION_STORAGE)
    @Optional() private sessionStorage: StorageService,

    private cryptoService: CryptoService
  ) {}

  /** token **/
  public setToken(token: string) {
    try {
      this.getStorageService().set(CurrentUserService.TOKEN_KEY, this.cryptoService.encrypt(token));
    } catch (e) {
      console.log('error: ' + e);
    }
  }

  public getToken(): string {
    try {
      return this.cryptoService.decrypt(this.getStorageService().get(CurrentUserService.TOKEN_KEY));
    } catch (e) {
      console.log('error: ' + e);
    }
  }


  /** login **/
  public getLogin(): string {
    try {
      return this.cryptoService.decrypt(this.getStorageService().get(CurrentUserService.LOGIN_KEY));
    } catch (e) {
      console.log('error: ' + e);
    }
  }


  public setLogin(login: string) {
    try {
      this.getStorageService().set(CurrentUserService.LOGIN_KEY, this.cryptoService.encrypt(login));
    } catch (e) {
      console.log('error: ' + e);
    }
  }

  public setUserId(userId: string) {
    try {
      this.getStorageService().set(CurrentUserService.USER_ID_KEY, this.cryptoService.encrypt(userId));
    } catch (e) {
      console.log('error: ' + e);
    }
  }


  /**selected project ID **/

  public setSelectedProjectId(selectedProjectId: string) {
    try {
      this.getStorageService().set(CurrentUserService.SELECTED_PROJECT_ID_KEY, this.cryptoService.encrypt(selectedProjectId));
    } catch (e) {
      console.log('error: ' + e);
    }
  }

  public getSelectedProjectId(): string {
    try {
      return this.cryptoService.decrypt(this.getStorageService().get(CurrentUserService.SELECTED_PROJECT_ID_KEY));
    } catch (e) {
      console.log('error: ' + e);
    }
  }

  /**password**/

  public getPassword(): string {
    try {
      return this.cryptoService.decrypt(this.getStorageService().get(CurrentUserService.PASSWORD_KEY));
    } catch (e) {
      console.log('error: ' + e);
    }
  }

  public setPassword(password: string) {
    try {
      this.getStorageService().set(CurrentUserService.PASSWORD_KEY, this.cryptoService.encrypt(password));
    } catch (e) {
      console.log('error: ' + e);
    }
  }

  public getUserId() {
    try {
      return this.cryptoService.decrypt(this.getStorageService().get(CurrentUserService.USER_ID_KEY));
    } catch (e) {
      console.log('error: ' + e);
    }
  }

  get logoutPressed(): boolean {
    return this._logoutPressed;
  }

  set logoutPressed(value: boolean) {
    this._logoutPressed = value;
  }


  public removeTokens() {
    this.setToken(null);
  }

  private getStorageService(): StorageService {
    try {
      if (this.localStorage) {
        return this.localStorage;
      } else if (this.sessionStorage) {
        return this.sessionStorage;
      }
    } catch (e) {
      console.log('error creating storage: ' + e);
    }
    console.log('not possible to set storage');
  }

}
