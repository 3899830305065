import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {StatService} from '../../views/stats/stat.service';
import {CurrentUserService} from '../currentuser.service';
import {SelectedDateService} from '../seleteddate.service';
import {BadgeService} from './badge.service';
import {BadgeEnum} from './badge.enum';

@Injectable()
export class BadgeValueService implements OnInit, OnDestroy {

  private visitorBadgeSubscription;
  private uniqueBadgeSubscription;
  private organicByDayBadgeSubscription;
  private referrerByDayBadgeSubscription;
  private titleByDayBadgeSubscription;
  private pathByDayBadgeSubscription;
  private heatmapByDayBadgeSubscription;

  constructor(
    private  _statService: StatService,
    private _currentUserService: CurrentUserService,
    private _selectedDateService: SelectedDateService,
    private badgeService: BadgeService
  ) {
  }

  ngOnInit(): void {
  }



  public populateBadgeValues() {
    const selectedProjectId = this._currentUserService.getSelectedProjectId();
    const formattedDate = this._selectedDateService.getFormattedDate();
    this.setVisitorsBadgeValue(selectedProjectId, formattedDate);
    this.setUniqueBadgeValue(selectedProjectId, formattedDate);
    this.setOrganicBadgeValue(selectedProjectId, formattedDate);
    this.setPathBadgeValue(selectedProjectId, formattedDate);
    this.setTitleBadgeValue(selectedProjectId, formattedDate);
    this.setReferrerBadgeValue(selectedProjectId, formattedDate);
    this.setHeatmapBadgeValue(selectedProjectId, formattedDate);
  }


  private setVisitorsBadgeValue(selectedProjectId: string, formattedDate: string | null) {
    this.visitorBadgeSubscription = this.badgeService.getVisitByDayBadge(selectedProjectId, formattedDate)
      .subscribe({
        next: (data) => {
          if (data.length > 0) {
            const value = data[0];
            this.badgeService.setBadgeValue(BadgeEnum.PAGE_LOADS, value);
          }
        }, 
        error: (error) => {
          console.log('error: ' + error);
        }
      });
  }

  private setUniqueBadgeValue(selectedProjectId: string, formattedDate: string | null) {
    this.uniqueBadgeSubscription = this.badgeService.getUniqueByDayBadge(selectedProjectId, formattedDate)
      .subscribe({
        next: (data) => {
          if (data.length > 0) {
            const value = data[0];
            this.badgeService.setBadgeValue(BadgeEnum.UNIQUE, value);
          }
        }, 
        error: (error) => {
          console.log('error: ' + error);
        }
      });
  }


  private setOrganicBadgeValue(selectedProjectId: string, formattedDate: string | null) {
    let value = '';
    this.organicByDayBadgeSubscription = this.badgeService.getOrganicByDayBadge(selectedProjectId, formattedDate)
      .subscribe({
        next: (data) => {
            value = data ? data[0] : '';
            this.badgeService.setBadgeValue(BadgeEnum.ORGANIC, value);
        },
        error: (error) => console.log('error: ' + error)
      });
  }

  private setPathBadgeValue(selectedProjectId: string, formattedDate: string | null) {
    let value = '';
    this.pathByDayBadgeSubscription = this.badgeService.getPathByDayBadge(selectedProjectId, formattedDate)
      .subscribe({
        next: (data) => {
          value = data ? data[0] : '';
          this.badgeService.setBadgeValue(BadgeEnum.PATH, value);
        },
        error: (error) => console.log('error: ' + error)
      });
  }


  private setReferrerBadgeValue(selectedProjectId: string, formattedDate: string | null) {
    let value = '';
    this.referrerByDayBadgeSubscription = this.badgeService.getReferrerByDayBadge(selectedProjectId, formattedDate)
      .subscribe({
        next: (data) => {
          value = data ? data[0] : '';
          this.badgeService.setBadgeValue(BadgeEnum.REFERRER, value);
        },
        error: (error) => console.log('error: ' + error)
      });
  }

  private setTitleBadgeValue(selectedProjectId: string, formattedDate: string | null) {
    let value = '';
    this.titleByDayBadgeSubscription = this.badgeService.getTitleByDayBadge(selectedProjectId, formattedDate)
      .subscribe({
        next: (data) => {
          value = data ? data[0] : '';
          this.badgeService.setBadgeValue(BadgeEnum.TITLE, value);
        },
        error: (error) => console.log('error: ' + error)
      });
  }

  private setHeatmapBadgeValue(selectedProjectId: string, formattedDate: string | null) {
    let value = '';
    this.heatmapByDayBadgeSubscription = this._statService.getTotalHeatmapCountByDay(selectedProjectId, formattedDate)
      .subscribe({
        next: (data) => {
          value = data ? data : '';
          this.badgeService.setBadgeValue(BadgeEnum.HEATMAP, value);
        },
        error: (error) => console.log('error: ' + error)
      });
  }

  ngOnDestroy(): void {

    if (this.visitorBadgeSubscription != null) {
      this.visitorBadgeSubscription.unsubscribe();
    }
    if (this.uniqueBadgeSubscription != null) {
      this.uniqueBadgeSubscription.unsubscribe();
    }
    if (this.organicByDayBadgeSubscription != null) {
      this.organicByDayBadgeSubscription.unsubscribe();
    }
    if (this.referrerByDayBadgeSubscription != null) {
      this.referrerByDayBadgeSubscription.unsubscribe();
    }
    if (this.referrerByDayBadgeSubscription != null) {
      this.referrerByDayBadgeSubscription.unsubscribe();
    }
    if (this.titleByDayBadgeSubscription != null) {
      this.titleByDayBadgeSubscription.unsubscribe();
    }
    if (this.heatmapByDayBadgeSubscription != null) {
      this.heatmapByDayBadgeSubscription.unsubscribe();
    }

  }




}
